// background colours
$pop1: red;
$pop2: green;

// background overlay
#modal-wrap {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: rgba(#000, .80);
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	z-index: 99999;
	display: none;
}

// show with JS
.blackout {
	display: block !important;
}

#curriculum-sec {
	padding: 50px 0 90px 0;
	@extend .clearfix;
	background: url(/i/design/welcome-bg.png) repeat-y;
	position: relative;
	border-bottom: 3px solid #fff;
	border-top: 3px solid #fff;

	&:before {
		position: absolute;
		content: "";
		background: #fff;
		width: 100%;
		height: 470px;
		top: 0px;
		left: 0px;
	}

	@media only screen and (max-width: 990px) {
		padding: 80px 0;
	}

	.wrapper {
		width: 80vw;

		@media only screen and (max-width: 540px) {
			width: 93vw;
	}

		&:before {
			position: absolute;
			content: "";
			background: #fff;
			width: 2000px;
			height: 200px;
			bottom: -90px;
			left: -200px;
			z-index: 0;
		}
	}

	h2 {
		margin: 0;
		padding: 40px 0 0 0;
		color: #041E50;
		font-weight: 300;
		font-size: 100px;
		position: relative;
		text-align: center;
		text-transform: uppercase;

		@media only screen and (max-width: 990px) {
			padding: 0;
			line-height: 100px;
		}

		@media only screen and (max-width: 700px) {
			font-size: 65px;
			line-height: 65px;
		}

		@media only screen and (max-width: 540px) {
			font-size: 50px;
			line-height: 50px;
		}
	}

	p {
		color: #041E50;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
		max-width: 800px;
		margin: 0 auto 60px;
	}


	ul {
		position: relative;
		max-width: 100%;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@extend .clearfix;


		// 1800

		li {
			background: url(/i/design/curriculum-item-bg.png) center bottom;
			cursor: pointer;
			position: relative;
			padding: 20px;
			display: inline-block;
			margin: 10px;
			vertical-align: top;
			transition: .3s;
			width: 250px;
			@extend .clearfix;
			height: 250px;
			box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.4);

			.li-item {
				display: flex;
			}

			// @media only screen and (max-width: 1350px) {
			// 	display: inline-block;
			// 	margin: 10px;
			// }

			// @media only screen and (max-width: 990px) {
			// 	max-width: 250px;
			// 	display: block;
			// 	margin: 0 auto 20px;
			// }

			.trigger {
				width: 100%;
				height: 100%;
			}

			img {
				object-fit: contain;
				vertical-align: baseline;
				display: block;
				margin: 25px auto;
				transition: .3s;
			}

			* {
				transition: .4s;
			}

			// title below the icon
			p.title {
				position: absolute;
				top: 77%;
				left: 50%;
				transform: translateX(-50%);
				font-size: 18px;
				font-weight: 300;
				text-transform: uppercase;
				margin: 0 auto;
				text-align: center;
				text-align: center;
				color: #fff;
				transition: .3s;
				width: 100%;

				// @media only screen and (max-width: 1800px) {
				// 	font-size: 25px;
				// }

				// @media only screen and (max-width: 1600px) {
				// 	font-size: 22px;
				// }

				// @media only screen and (max-width: 1350px) {
				// 	font-size: 20px;
				// }
			}

			.multi-line {
				top: 73% !important;

			}

			.curr-more {
				display: block;
				color: #fff;
				font-weight: bold;
				font-size: 16px;
				text-transform: uppercase;
				text-align: center;
				box-shadow: inset 0 0 0 2px #FBE605;
				transition: .5s;
				margin: 30px auto 0;
				text-decoration: none;
				width: 250px;
				padding: 20px 10px;

				&:hover {
					filter: brightness(130%);
					box-shadow: inset 0 0 0 4px #fff;
				}
			}

			// &:nth-of-type(1) {
			// 	.curr-pop {
			// 		border: 5px solid $pop1;

			// 		h2 {
			// 			color: $pop1;
			// 		}

			// 		.curr-more {
			// 			background: $pop1;
			// 		}
			// 	}
			// }

			// &:nth-of-type(2) {
			// 	.curr-pop {
			// 		border: 5px solid $pop2;

			// 		h2 {
			// 			color: $pop2;
			// 		}

			// 		.curr-more {
			// 			background: $pop2;
			// 		}
			// 	}
			// }

			// pop up box styles
			.curr-pop {
				width: 500px;
				max-width: 90%;
				padding: 3%;
				box-sizing: border-box;
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				cursor: default;
				outline: 1px solid rgba(#FBE504, 1);
				outline-offset: 10px;
				z-index: 999999;
				background: url(/i/design/welcome-bg.png);

				h2 {
					color: #fff;
					font-size: 35px;
					margin: 0 auto 20px;
					font-weight: 800;
				}

				p {
					color: #fff;
					font-size: 18px;
					margin: 0 auto;
				}

				.close {
					position: absolute;
					right: 30px;
					top: 20px;
					color: #fff;
					font-weight: bold;
					cursor: pointer;
					padding: 5px;
				}
			}

			// jquery classes
			.hide-me {
				opacity: 0;
				visibility: hidden;
				top: 60%;
			}

			&:hover {
				img {
					filter: brightness(120%);
					transform: translateY(-10px);
				}

				p.title {
					letter-spacing: 1px;
				}
			}
		}
	}
}

// #curriculum-sec { padding: 50px 0; }
// #curriculum-sec ul { display: block; clear: both; }

// #curriculum-sec ul li { margin: 0 auto 100px; display: block; width: 90%; max-width: 700px; height: auto; padding: 20px; }
// #curriculum-sec ul li * { cursor: text; }
// #curriculum-sec ul li .close { display: none; }

// #curriculum-sec ul li:hover img { opacity: 1; }
// #curriculum-sec ul li img { display: block; margin: 0 auto; position: relative; left: auto; right: auto; top: auto; transform: none; background: #222; border: 3px dotted #fff; padding: 10px; opacity: 1!important; }

// #curriculum-sec ul li p.title { opacity: 1; position: relative; top: auto; left: auto; transform: none; background: #444; border: 3px dotted #fff; padding: 10px; color: #fff; }

// #curriculum-sec ul li .curr-pop { width: 100%;  position: relative; top: auto; left: auto; transform: none; opacity: 1; visibility: visible; outline: none; padding: 50px; margin: 50px auto; }

// #curriculum-sec ul li p.title { bottom: -20px!important; }

// #curriculum-sec ul li .curr-pop h2,
// #curriculum-sec ul li .curr-pop p,
// #curriculum-sec ul li .curr-pop .curr-more { background: #666; border: 3px dotted #fff; padding: 10px; color: #fff; height: auto; line-height: auto; }