// <section id="social">

// <!-- Iframe container -->
// <!-- <iframe src="/inc/social/index.asp"></iframe> -->

// <!-- Close button -->
// <div id="close-social"><img src="/i/design/close.png" width="42" height="42" alt="Close"><br />Close</div>

// <!-- Sidebar -->
// <div id="sidebar">
// 	<h2>Social Media Posts</h2>
// </div>

// </section>

// // ------------------------------ 	Social Wall

// var isSliderOpen = false;
// var socialWidth = $('section#social').width();
// possible fix for sticky-out bit
// // var socialWidth = window.innerWidth;

// $('section#social').css({
// 	'right': '-' + socialWidth + 'px',
// 	'visibility': 'visible'
// });

// $('section#social div#sidebar').click(function () {
// 	if (isSliderOpen === false) {
// 		$('section#social').animate({
// 			right: 0
// 		}, 1000);
// 		$('div#sidebar').addClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible',
// 			'-webkit-overflow-scrolling': 'touch'
// 		});
// 		isSliderOpen = true;
// 	} else {
// 		$('section#social').animate({
// 			right: '-' + socialWidth + 'px'
// 		}, 1000);
// 		$('section#social div#sidebar').removeClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible'
// 		});
// 		isSliderOpen = false;
// 	}
// });
// $('#close-social').click(function () {
// 	$('section#social').animate({
// 		right: '-' + socialWidth + 'px'
// 	}, 1000);
// 	$('section#social div#sidebar').removeClass('open');
// 	$('section#social').css({
// 		'overflow': 'visible'
// 	});
// 	isSliderOpen = false;
// });

// section#social {
// 	width: 400px;
// 	height: 520px !important;
// 	position: fixed;
// 	top: 21%;
// 	left: auto;
// 	visibility: hidden;
// 	background: rgba(0, 37, 76, 0.8);
// 	z-index: 80;

// 	iframe {
// 	  width: 100%;
// 	  height: 100%;
// 	  overflow: hidden;
// 	  position: absolute !important;
// 	  left: 20px;
// 	  top: 20px;
// 	}
// 	@media only screen and (max-width: 1000px) {
// 	  visibility: hidden !important;
// 	  display: none !important;
// 	}
// 	// Right sidebar

// 	  h2 {
// transform: rotate(90deg);
// text-align: center;
// position: absolute;
// left: -53%;
// top: 108px;
// color: #fff;
// font-size: 24px;
// font-weight: 300;
// white-space: nowrap;
// margin: 0;
// 	  }
// 	  &:hover {
// 		cursor: pointer;
// 	  }
// 	}
//   }

section#social {
	width: 100%;
	height: 100% !important;
	position: fixed;
	top: 0;
	left: auto;
	visibility: hidden;
	z-index: 99999;
	background: rgba(0, 0, 0, .8);

	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#sidebar {
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		z-index: 99999;

		// background: #00b190;

		// height: 70vh;
		// width: 75px;

		// left: -75px;
		// top: 15vh;

		left: -60px;
		top: 34%;
		height: 290px;
		width: 60px;
		z-index: 40;
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		background: #041E50;


		&::before {
			position: absolute;
			content: "";
			background: url(/i/design/social-media-bg.png) no-repeat;
			height: 300px;
			width: 720px;
			left: -7px;
			top: 7px;
			z-index: -1;
		}

		h2 {
			@include center;
			transform: rotate(90deg) translateY(-50%);
			text-align: center;
			position: absolute;
			left: -53%;
			top: 47%;
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			white-space: nowrap;
			text-transform: uppercase;
			margin: 0;
		}
	}
}

#close-social {
	position: absolute;
	top: 15px;
	right: 30px;
	font-size: 13px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
}

#close-social img {
	padding: 14px 13px 13px 13px;
	border-radius: 50%;
	margin-bottom: 6px;
}

#close-social:hover {
	opacity: .7;
}

#close-social:hover img {
	opacity: .7;
}

.backstretch {
	height: 100%;
}

@media only screen and (max-width: 1170px) {

	section#social {
		display: none;
	}
}