#animated-stats {
  text-align: center;
  position: relative;

  .wrapper {
    text-align: center;
    padding: 80px 0 150px;
    width: 100%;

    @media only screen and (max-width: 990px) {
      padding: 50px 0;
    }
  }

  h2 {
    font-size: 90px;
    color: #041E50;
    margin: 0;
    font-weight: 300;
    z-index: 20;
    position: relative;
    text-transform: uppercase;

    @media only screen and (max-width: 750px) {
      font-size: 65px;
      line-height: 65px;
    }

    @media only screen and (max-width: 430px) {
      font-size: 50px;
      line-height: 50px;
      padding: 0 8px;
    }

    &:before {
      position: absolute;
      content: "";
      background: #fff;
      width: 100%;
      height: 200px;
      top: -60px;
      left: 0;
      z-index: -1;
      pointer-events: none;
      user-select: none;

      @media only screen and (max-width: 750px) {
        top: -100px;
      }

      @media only screen and (max-width: 360px) {
        top: -70px;
      }
    }
  }
}

#performance-group {
  max-width: 100%;
  text-align: center;
  font-size: 0px;
  text-align: center;

  @media only screen and (max-width: 1500px) {
    transform: scale(.9);
  }

  text {
    font-size: 70px;
    font-weight: 500;
    fill: #fff;
    font-weight: 300;
  }

  .donut-item {
    width: 246px;
    height: 246px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2%;
    position: relative;
    vertical-align: top;
    z-index: 1;

    &:before {
      @include center;
      @include before(calc(100% - 3px), calc(100% - 3px));
      outline: 2px solid #f9e512;
      outline-offset: -14px;
      border-radius: 50%;
      z-index: -1;
    }

    @media only screen and (max-width: 1800px) {
      margin: 0 20px;
    }

    @media only screen and (max-width: 1500px) {
      margin: 0 -10px;
      transform: scale(.8);
    }

    @media only screen and (max-width: 990px) {
      margin: 0 30px 100px;
    }

    @media only screen and (max-width: 350px) {
      scale: 0.90;
    }

    p {
      font-size: 18px !important;
      color: #fff;
      line-height: 24px;
      text-align: center;
      margin: 30px auto 0;
      font-weight: lighter;
      max-width: 240px;

      @media only screen and (max-width: 1500px) {
        margin: 30px auto 20px;
      }
    }
  }
}

#donut {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  z-index: 99;
  position: relative;
}

path {
  position: relative;
  z-index: 9999;
}

path.color0 {
  fill: #f9e512;
  animation: chloes-gradient 5s linear forwards infinite;

  @keyframes chloes-gradient {

    0%,
    100% {
      fill: #f9e512;
    }

    70% {
      fill: #fff;
    }
  }
}

path.color1 {
  fill: none;
}