// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2082 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 800px;

  &:before {
    @include before(100%, 100%);
    background: rgba(1, 19, 65, 0.4);
    top: 0;
    left: 0;
  }

  .backstretch {
    &:after {
   display: none;
    }
  }

}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 500px;
  z-index: 1;

  // &:before {
  //   @include before(100%, 100%);
  //   background: rgba(1, 19, 65, 0.4);
  //   top: 0;
  //   left: 0;
  // }

  @media only screen and (max-width: 1250px) {
    min-height: 550px;
  }

  @media only screen and (max-width: 990px) {
    min-height: 570px;
  }

}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 100%);
    background: rgba(1, 19, 65, 0.7);
    top: 0;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  top: 400px;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 23px;
  line-height: 25px;
  font-weight: 400;
  z-index: 999;
  animation: fadeIn 6s ease 0s 1 normal forwards;

  &:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 90px;
    height: 1px;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (max-width: 990px) {
      display: none;
    }

  }

  @media only screen and (max-width: 1245px) {
    top: 460px;
  }

  @media only screen and (max-width: 990px) {
    top: 490px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
    line-height: 19px;
  }


}

.video-background { position: absolute;  top: 0; right: 0; bottom: 0; left: 0;  z-index: -99;}
.video-foreground, .video-background iframe {position: absolute;  top: 0; left: 0; width: 100%; height: 100%; min-height: 750px; pointer-events: none; overflow: hidden; }
.video-background iframe { height: 130%; top: -15%; left: 0; right: 0; margin: 0 auto; object-fit: fill; }

@media only screen and (max-width: 990px) {
  .video-background {
    display: none;
}
}