// <!-- rotating values -->
// <div class="bx-cont">
//   <ul id="bxslider">
//     <li>What do you love about school?</li>
//     <li>Insert your quotes here.</li>
//     <li>Press ENTER to add a new quote.</li>
//   </ul>
// </div>


// BXSLIDER

.bx-cont {
  margin: 0 auto;
  // max-width: 90%;
  text-align: left;
  color: #fff;

  &:nth-of-type(1) {
    margin: 0;
    max-width: 700px;
  }

  ul#bxslider {

    li {
      padding: 0;
      background: none;
      text-align: left;
      max-width: 100%;
      margin: 0 auto;
      color: #FFF;
      font-size: 24px;
      font-weight: 300;
      
    }
  }

  ul#bxslider1 {

    li {
      padding: 0;
      background: none;
      text-align: left;
      max-width: 100%;
      margin: 0 auto;
      color: #FFF;
      font-size: 22px;
      font-weight: 200;
      line-height: 25px;

      @media only screen and (max-width: 1080px) {
        font-size: 18px;
    }

      @media only screen and (max-width: 990px) {
        text-align: center;
      }

      @media only screen and (max-width: 770px) {
        font-size: 19px;
        line-height: normal;
      }

      strong {
        position: relative;
        top: 20px;
      }
    }

    .bx-viewport {
      min-height: 400px !important;
      position: relative;
      top: 50px;
    }

  }
}

// BXSLIDER CONTROLS - INDICATORS

.bx-controls {
  display: block;
  text-align: center;
  margin: 30px auto;
  border-radius: 50%;

  &:nth-of-type(1) {
    margin: 0;
  }

  .bx-pager-item {
    display: inline-block;
    height: 5px;
    width: 70px;
    margin: 0 10px;
    border-radius: 50%;

    .bx-pager-link {
      height: 5px;
      width: 70px;
      background: #4B5676;
      overflow: hidden;
      color: transparent;
      display: inline-block;
      margin: 0 auto;
      border-radius: 2px;
    }

    .active {
      background: #BE0638;
    }
  }
}


// BXSLIDER CONTROLS - ARROWS

// .bx-prev {position: absolute; color: transparent; left: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-left.png) no-repeat center; background-size: contain;}

// .bx-next {position: absolute; color: transparent; right: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-right.png) no-repeat center; background-size: contain;}


// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}