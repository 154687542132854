// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

$slideOut-panel-bg: rgba(0, 37, 76, 0.8);
$link-bg-colour: #222;
$link-bg-colour-hover: rgba(255, 255, 255, .1);
$links-colour: #fff;

$slide-click-colour: #fff;

$mobile-tab-color:  rgb(0, 37, 76);

/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 250px;
	top: 40%;
	margin: 0 !important;
	left: -250px;
	background: $slideOut-panel-bg;
	padding: 0px;
	z-index: 999;
}

/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 70px;
	cursor: pointer;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background: #BF0639;
		height: 59px;
		width: 3px;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	span {
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 55%;
		font-size: 24px;
		font-weight: 300;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -68px;
		margin-top: -37px;

		// &:before{
		// 	content: "";
		// 	width: 7px;
		// 	height: 12px;
		// 	background: url(/i/design/arrow-open-close.png) no-repeat center center;
		// 	position: absolute;
		// 	left: 48%;
		// 	top: 57%;
		// 	transform: rotate(90deg);
		// }
	}
}

/* Change the arrow  */
.open span::before {
	transform: rotate(-90deg) !important;
}

.flip {
	display: none;
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
}

/* Box with links  */
ul.quick-links {
	width: 240px;
	display: block;
	text-align: center;
	overflow: hidden;

	li {
		background: none;
		margin: 0 0 5px 0;
		padding: 0;
		display: block;

		&:not(:first-child) {
			border-top: 1px solid rgba(255, 255, 255, 0.3);
		}

		&:hover {
			
			a {
				font-weight: 400;
			}
		}

		a {
			// background: $link-bg-colour url(/i/design/arrow-quick-links.png) no-repeat left 15px center;
			color: $links-colour;
			display: block;
			text-align: left;
			transition: .3s;
			font-weight: 300;
			font-size: 16px;
			padding: 8px 10px 8px 45px;


		}
	}
}

@media only screen and (min-width: 990px) {

	#slideContent {
		display: table !important;
	}

	ul.quick-links {
		vertical-align: middle;
		display: table-cell;
	}
}

@media only screen and (max-width: 990px) {

	#slideOut {
		position: absolute;
		width: 100%;
		top: 44px;
		min-height: 0px;
		left: 0;
		padding: 0px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
		border-radius: 0;
		margin: 0 !important;
		background: $mobile-tab-color;
	}

	#slideClick {
		display: none;

		span {
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent {
		float: none;
	}

	ul.quick-links {
		width: 100%;

		li {
			a {
				padding: 6px 0 6px 0;
				text-align: center;
				background-position: 8% 10px;
				font-size: 15px;
			}
		}
	}

	.flip {
		z-index: 800;
		margin: 0;
		padding: 5px 0;
		cursor: pointer;
		font-size: 13px;
		color: #fff;
		display: block;

		img {
			display: block;
			margin: 0 auto;
			transform: rotate(90deg);
		}
	}

	.open img {
		transform: rotate(-90deg);
	}
}