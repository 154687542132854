// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #052259;
$h2-color: #052259;
$h3-bg-color: #052259;
$h4-bg-color: #052259;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Futura PT';
}

.body_2082 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_2082 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;


}

h2 {
	font-size: 25px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
	font-weight: 600;
}

h3 {
	font-size: 16px;
	font-weight: 600;
	margin: 25px 0 10px 0;
	padding: 7px 10px 5px 10px;
	background: $h3-bg-color;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
	font-weight: lighter;
	font-size: 22px;
	line-height: 26px;
}

b,
strong {
	color: #343434;
	font-size: 30px;
	font-weight: 400;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
	font-weight: lighter;
	font-size: 22px;
	line-height: 26px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;

	@media only screen and (max-width: 990px) {
		margin: 40px auto 0;
	}
}

// fix home page wrapper called in t.asp
.body_2082 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0 auto;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 1600px;

	@media only screen and (max-width: 1640px) {
		width: 1430px;
	}

	@media only screen and (max-width: 1470px) {
		width: 100%;
	}

	@media only screen and (max-width: 1300px) {}
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 125px;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	// animation: fadeIn 5s ease 0s 1 normal forwards;

	@media only screen and (max-width: 1245px) {
		top: 190px;
	}

	@media only screen and (max-width: 990px) {
		margin: 0 !important;
		top: 215px;
	}

	@media only screen and (max-width: 580px) {
		top: 280px;
	}

	@media only screen and (max-width: 550px) {
		top: 330px;
	}

	a {
		span {}

		img#logo {
			margin: 0 auto;
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0;
	left: 0;
	top: 10px;
	z-index: 99;

	@media only screen and (max-width: 1245px) {
		top: 40px;
	}

	@media only screen and (max-width: 990px) {
		top: 70px;
	}

	@media only screen and (max-width: 580px) {
		top: 210px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;
		transition: 0.3s;

		&:hover {
			translate: 0 -3px;
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		#twitter-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/twitter-ic.png) no-repeat center;
			// animation: slideIn 1s both;

			// @keyframes slideIn {
			// 	0% {
			// 		opacity: 0;
			// 		transform: translateY(-250px);
			// 	}

			// 	100% {
			// 		opacity: 1;
			// 		transform: translateY(0);
			// 	}
			// }
		}
	}
}

// -----------------------------------//
// TRUST
// -----------------------------------//

.trust {
	position: absolute;
	z-index: 999;
	top: -173px;
	right: 0;
	width: 191px;
	transition: 1s;

	&:hover {
		top: 0px;
	}

	@media only screen and (max-width: 990px) {
		top: -129px;

		&:hover {
			top: 44px;
		}
	}

	@media only screen and (max-width: 580px) {
		left: 50%;
		right: auto;
		transform: translateX(-50%);
	}

	img {
		position: relative;

		&:hover {}
	}

	.trust-text {
		background: #052259;
		padding: 5px 8px;
		position: relative;

		p,
		a {
			color: #fff;
			font-size: 15px;
			line-height: 16px;
		}

		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 99;
	// animation: slideIn 0.8s both;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
	// animation: slideIn 0.6s both;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 60px 0 100px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	background: url(/i/design/welcome-bg.png);
	border-top: 5px solid #fff;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/ip-watermark.png);
		height: 917px;
		width: 996px;
		left: -590px;
		top: -100px;
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/ip-watermark.png);
		height: 917px;
		width: 996px;
		right: -500px;
		bottom: -90px;
		visibility: visible !important;
	}

	h1 {
		color: #fff;
		font-size: 100px;
		line-height: 100px;
		font-weight: 300;
		margin: 0 auto 20px;
		position: relative;
		width: calc(1500px - 250px);
		max-width: 76%;


		@media only screen and (max-width: 990px) {
			text-align: center;
			padding: 0;
			margin: 50px auto 0;
			font-size: 80px;
			line-height: 80px;
			padding: 0 15px;
			max-width: 90%;
		}

		@media only screen and (max-width: 570px) {
			font-size: 50px;
			line-height: 50px;
		}

	}
}

// home page alternate
.body_2082 #mainholder {
	padding: 0;
	margin: 0;
	background: #fff;
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 200px 0 100px 0;
	background: url(/i/design/welcome-bg.png) repeat-y;
	position: relative;
	border-top: 3px solid #fff;

	@media only screen and (max-width: 1370px) {}

	@media only screen and (max-width: 990px) {
		padding: 70px 0;
	}

	@media only screen and (max-width: 700px) {}

	@media only screen and (max-width: 420px) {}

	.wrapper {
		width: 1300px;

		@media only screen and (max-width: 1370px) {
			width: 990px;
		}

		@media only screen and (max-width: 990px) {
			width: auto;
		}
	}

	.welcome-pic {
		position: absolute;
		font-weight: 600;
		text-transform: uppercase;
		color: #fff;
		font-size: 12px;
		bottom: 85px;
		left: 30px;
	}

	.read-more {
		position: relative;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 300;
		margin: 60px 0 0 0;

		@media only screen and (max-width: 990px) {
			margin: 20px 0 0 0;
		}

		@media only screen and (max-width: 340px) {}

		&:hover {

			&:before {
				transform: translateX(12px);
			}
		}

		&:before {
			position: absolute;
			content: "";
			background: url(/i/design/yellow-arrow.png);
			height: 10px;
			width: 278px;
			bottom: -10px;
			transition: 0.6s;

			@media only screen and (max-width: 340px) {
				background-size: contain;
				max-width: 250px;
			}
		}

		a {
			display: block;
			height: 100%;
			color: #FBD624;
		}
	}

	.welcome-paragraph {
		position: relative;
		background: #052259 url(/i/design/welcome-paragraph-bg.png) bottom no-repeat;
		box-shadow: 0px 0px 29px 5px rgba(0, 0, 0, 0.1);
		width: 810px;
		float: right;
		padding: 50px 200px 80px;

		@media only screen and (max-width: 1370px) {
			width: 80%;
			padding: 50px 90px 80px 230px;
		}

		@media only screen and (max-width: 1050px) {
			width: 77%;

		}

		@media only screen and (max-width: 990px) {
			width: 85%;
			padding: 0;
			background: none;
			box-shadow: none;
			float: none;
			margin: 0 auto;
		}

		h2 {
			font-weight: 300;
			color: #fff;
			font-size: 100px;
			margin: 0 0 0 15px;
			text-transform: uppercase;

			@media only screen and (max-width: 1050px) {}

			@media only screen and (max-width: 990px) {
				margin: 0;
				text-align: center;
			}

			@media only screen and (max-width: 600px) {
				font-size: 65px;
			}

			@media only screen and (max-width: 420px) {
				font-size: 45px;
			}
		}

		p {
			font-weight: 300;
			color: #fff;
			font-size: 22px;
			font-weight: 300;
			line-height: 30px;
			text-align: justify;
			position: relative;

			&:before {
				position: absolute;
				content: "";
				background: url(/i/design/lines.png);
				height: 468px;
				width: 99px;
				top: -70px;
				right: -60px;

				@media only screen and (max-width: 1370px) {
					top: -140px;
				}

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}

			&:nth-of-type(1) {
				text-align: left;
				font-weight: normal;
				font-size: 17px;
				margin: 0 0 20px 0;

				@media only screen and (max-width: 990px) {
					text-align: center;
				}

				&::before {
					display: none;
				}

				span {
					display: block;
					white-space: nowrap;
					text-transform: uppercase;
					font-size: 30px;
					line-height: 25px;

					@media only screen and (max-width: 550px) {
						white-space: normal;
					}
				}
			}
		}
	}

	.welcome-img {
		position: absolute;
		top: -60px;
		left: 0px;
		transition: 0.3s;

		&:hover {
			filter: brightness(110%);
		}

		@media only screen and (max-width: 1370px) {
			width: 400px;
			height: auto;
		}

		@media only screen and (max-width: 1050px) {
			left: 35px;
		}

		@media only screen and (max-width: 990px) {
			position: relative;
			margin: 40px auto;
			top: auto;
			left: auto;

			img {}
		}

		@media only screen and (max-width: 500px) {
			max-width: 400px;
			min-width: 290px;
		}

		@media only screen and (max-width: 490px) {
			max-width: 290px;
			min-width: none;
		}
	}
}

// -----------------------------------//
// TESTIMONIALS
// -----------------------------------//

#testimonials {
	background: #374366 url(/i/design/testimonials-bg.png) no-repeat;
	min-height: 491px;
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	position: relative;
	// padding: 50px 0;

	@media only screen and (max-width: 1400px) {
		min-height: auto;
	}

	.wrapper {
		// padding: 0 0 0 350px;
		width: 100%;

	}

	.flex {
		display: flex;
		gap: 85px;
		align-items: center;

		@media only screen and (max-width: 1200px) {
			gap: 40px;
		}

		@media only screen and (max-width: 990px) {
			gap: 0px;
			width: 92%;
		}

		.left {
			flex-shrink: 0;
			width: 720px;

			@media only screen and (max-width: 1400px) {
				width: 500px;
				background: url(/i/design/testimonial-img.png);
				background-size: cover;

				img {
					// display: none;
				}
			}

			@media only screen and (max-width: 990px) {
				background: none;
				width: 0px;
			}
		}

		.right {
			width: 70%;
			padding: 50px 0;

			@media only screen and (max-width: 1400px) {
				padding: 10px 0;
			}

			@media only screen and (max-width: 990px) {
				width: 100%;
				padding: 50px 0;
			}

			.bx-cont {
				@media only screen and (max-width: 1120px) {
					position: relative;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	// &:before {
	// 	position: absolute;
	// 	content: "";
	// 	background: url(/i/design/testimonial-img.png);
	// 	height: 100%;
	// 	width: 720px;
	// 	top: 0;
	// }

	h2 {
		position: relative;
		text-align: right;
		width: fit-content;
		display: block;
		color: #BE0638;
		font-size: 35px;
		font-weight: 300;

		@media only screen and (max-width: 990px) {
			text-align: center;
			width: 100%;
		}

		span {
			position: relative;
			left: 0px;
			color: #fff;
			font-size: 50px;
			display: block;
		}

		&:before {
			position: relative;
			display: block;
			content: '';
			background: url(/i/design/quote-mark.png);
			width: 94px;
			height: 75px;
			left: 0;
			top: -10px;

			@media only screen and (max-width: 1400px) {
				top: 0px;
				scale: 0.9;
			}

			@media only screen and (max-width: 990px) {
				left: 50%;
				transform: translateX(-50%);

			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, 1);
	@extend .clearfix;
	position: relative;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		color: #fff;
		font-size: 100px;
		position: absolute;
		font-weight: 300;
		top: -140px;
		left: 0;
		display: none;


		@media only screen and (max-width: 990px) {
			font-size: 50px;
			top: -110px;
			white-space: normal;
			position: relative;
			text-align: center;
			color: #052359;
			top: auto;
			left: auto;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/icons/bullet_yellow.png) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY & NEWS
// -----------------------------------//

#news-diary-sec {
	background: url(/i/design/diary-bg.png);
	height: 837px;
	border-bottom: 3px solid #fff;
	padding: 50px 0;

	@media only screen and (max-width: 800px) {
		height: auto;
		background: #00254C;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
		width: 990px;

		@media only screen and (max-width: 1200px) {
			justify-content: center;
			gap: 20px;
		}

		@media only screen and (max-width: 990px) {
			width: 90%;
		}

		@media only screen and (max-width: 800px) {
			flex-direction: column;
			align-items: center;
		}
	}

	//Diary
	#SideHeadingNews {
		width: 390px;

		@media only screen and (max-width: 420px) {
			width: 95%;
		}

		;

		h2 {
			color: #BF0639;
			font-weight: 300;
			font-size: 35px;
			line-height: 50px;
			text-align: left;

			span {
				font-size: 50px;
				color: #fff;
				font-weight: 300;
			}
		}

		ul.SideList {

			li {

				p.date {
					margin: 20px 0 0 0;
					color: #BE0638;
					font-weight: bold;
					text-transform: uppercase !important;
					font-size: 16px;


					span {
						display: inline !important;
					}
				}

				p.title {
					color: #fff;
					font-weight: 300;
					font-size: 16px;
					max-width: 390px;


					span {
						height: auto !important;
					}
				}
			}
		}

		.btn-wrap {


			a {
				margin: 40px 0 0 0;
				background: #BE0638;
				display: block;
				height: 50px;
				width: 100%;

				img {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					float: right;
					margin: 0 10px 0 0;
				}

			}
		}
	}

	//News
	#SideHeadingDiary {
		width: 390px;

		@media only screen and (max-width: 420px) {
			width: 95%;
		}

		;

		h2 {
			color: #BF0639;
			font-weight: 300;
			font-size: 35px;
			line-height: 50px;
			text-align: left;

			span {
				font-size: 50px;
				color: #fff;
				font-weight: 300;
			}
		}

		ul.SideList {
			display: flex;
			flex-direction: column;
			gap: 20px;



			li {
				background: url(/i/design/diary-item.png);
				height: 115px;


				.li-flex {
					display: flex;
					height: 100%;

				}

				p.date {
					height: 100%;
					width: 170px;
					color: #fff;
					position: relative;
					text-transform: uppercase;
					top: 83%;
					transform: translateY(-50%);
					font-weight: 600;
					font-size: 24px;

					span {
						// text-transform: uppercase;
						// position: relative;
						// top: 50%;
						// transform: translateY(-50%);
						// font-weight: 600;
						// font-size: 24px;
					}
				}

				p.title {
					color: #fff;
					font-weight: 300;
					height: auto !important;
					padding: 15px 25px;
					text-align: left;

					span {
						height: auto !important;
						max-height: 100px;
						font-size: 16px;

						@media only screen and (max-width: 380px) {
							position: relative;
							left: 10px;
						}
					}
				}
			}
		}

		.btn-wrap {


			a {
				margin: 40px 0 0 0;
				background: #BE0638;
				display: block;
				height: 50px;
				width: 100%;

				img {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					float: right;
					margin: 0 10px 0 0;
				}

			}
		}
	}
}

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// animated stats
// -----------------------------------//

#animated-stats {
	background: url(/i/design/welcome-bg.png) repeat-y;
	// min-height: 470px;

	.wrapper {
		width: 85vw;

		@media only screen and (max-width: 350px) {
			width: 100vw;
		}
	}

}

// -----------------------------------//
// curriculum pop-ups
// -----------------------------------//

#curriculum {
	background: url(/i/design/curriculum-bg.png);
}

// -----------------------------------//
// imgs
// -----------------------------------//

#images {
	position: relative;
	min-height: 600px;
	margin: 100px 0;
	pointer-events: none;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 990px) {
		justify-content: center;
	}

	&:before {
		position: absolute;
		content: "";
		border: 2px solid #e4e4e4;
		width: 70%;
		height: 90%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	img {
		position: relative;
		margin: 0 auto;
		display: block;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	ul {
		display: none;
		margin: 0;
		padding: 0;
		max-width: 85%;

		li {
			background: none;
			margin: 0 0 20px 0;
			padding: 0;
		}

		@media only screen and (max-width: 990px) {
			display: block;

			img {
				display: block;
				box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
			}
		}
	}

}


// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5% 0 70px;
	background: #052359 url(/i/design/sidemenu-bg.png) bottom no-repeat;
	background-size: contain;
	border-bottom: 8px solid #FBE605;
	box-shadow: 10px 15px 15px 0px rgba(0, 0, 0, 0.2);

	@media only screen and (max-width: 990px) {
		margin: 0 0 50px 0;
	}

	h2 {
		display: block;
		text-align: center;
		font-size: 30px;
		padding: 20px 0;
		margin: 0 0 20px 0;
		color: #fff;
		position: relative;

		&:before {
			position: absolute;
			content: "";
			background: #FBE605;
			width: 75px;
			height: 1px;
			bottom: 0px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);

			a {
				padding: 7px 20px;
				font-size: 22px;
				color: #fff;
				font-weight: 300;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	background: url(/i/design/footer-bg.jpg);
	position: relative;
	font-weight: 300;
	border-top: 3px solid #fff;
	padding: 30px 0;

	.flex {
		display: flex;
		gap: 40px;
		align-items: center;
		justify-content: center;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
			gap: 0px;
		}

		.left {
			text-align: right;

			h2.footer-heading {
				padding: 0;
				margin: 0 0 10px 0;
				color: #fff;
				font-weight: 300;
				font-size: 24px;
				text-transform: uppercase;
				font-weight: 500;

				@media only screen and (max-width: 530px) {}
			}

			p {
				font-size: 18px;
			}

			@media only screen and (max-width: 990px) {
				order: 2;
			}
		}

		.centre {
			flex-shrink: 0;

			@media only screen and (max-width: 990px) {
				margin: 0 0 20px 0;
			}
		}

		.right {
			font-size: 18px;

			@media only screen and (max-width: 530px) {
				font-size: 15px;
				line-height: 15px;
			}

			@media only screen and (max-width: 990px) {
				order: 2;
				text-align: center;
			}

			p.address {

				@media only screen and (max-width: 530px) {
					font-size: 15px;
					line-height: 15px;
				}
			}

			p.telephone {
				display: inline;
				font-weight: 400;

				@media only screen and (max-width: 530px) {
					font-size: 15px;
					line-height: 15px;
				}
			}

			a[href^="mailto:"] {
				color: #FCD622;
				font-weight: 400;

				&:hover {
					text-decoration: underline;
				}

				@media only screen and (max-width: 990px) {
					display: inline !important;
				}
			}
		}
	}

	.wrapper {
		text-align: left;
		font-size: 24px;
		line-height: 30px;
		position: relative;
		margin: 0 auto;

		@media only screen and (max-width: 1500px) {}

		@media only screen and (max-width: 1070px) {}

		@media only screen and (max-width: 990px) {}

		@media only screen and (max-width: 500px) {}

	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}





// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 50px;
	line-height: 50px;
	background: url(/i/design/footer-bg.jpg);
	overflow: hidden;
	@extend .clearfix;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
		line-height: 50px;

		@media only screen and (max-width: 990px) {
			line-height: normal;
		}
	}

	a {
		color: #fff;
	}

	#copyright,
	#credit {
		a {
			color: #FBE605;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	#copyright {
		float: left;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #024F9F;
$blockquote-border-color: #eeeeee;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #FEF708;
$blockquote-font-size: 22px;